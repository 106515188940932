<template>
  <div class="main-container" style="height: 100%">
    <app-main style="height: 100%"/>
  </div>
</template>

<script>
import { AppMain } from './components'

export default {
  name: 'LayoutWorkspace',
  components: { AppMain }
}
</script>
